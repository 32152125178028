import React, { Component } from 'react';
import Code7 from '../../imgs/code7-logo.png';
import './index.css';
import boleto from '../../imgs/modelo-boleto-code7.png';

class telaCPF extends Component {
	constructor(props) {
		super(props);
		this.state = {
			linkPDF: `https://docs.google.com/viewer?url=${boleto}&embedded=true`,
		};
	}

	handleVisualzarClicked = () => {
		const win = window.open(boleto, '_blank');
		win.focus();
	};

	render() {
		return (
			<div className="Container-pdf">
				<img className="Logo" src={Code7} alt="code7"></img>
				<img className="boleto" src={boleto} alt="boleto"></img>
				<button
					onClick={this.handleVisualzarClicked}
					className="ButtonDownload"
				>
					Download Boleto
				</button>
			</div>
		);
	}
}

export default telaCPF;
