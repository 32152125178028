import { createBrowserHistory } from 'history';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert2';
import './index.css';
import BoletoGerado from './pages/boletoGerado/boletoGerado';
import Carregamento from './pages/carregamento/carregamento';
import TelaCPF from './pages/telaCPF/telaCPF';
import TelaInicial from './pages/telaInicial/telaInicial';
import TelaPDF from './pages/telaPDF/telaPDF';
import TelaErro from './pages/telaErro/telaErro';
import CPFService from './services/cpf';
import BoletoService from './services/boleto';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from 'react-router-dom';

ReactGA.initialize('UA-96535900-13');
ReactGA.pageview(window.location.pathname);

const history = createBrowserHistory();

// Get the current location.
const location = history.location;

class App extends Component {
	constructor(props) {
		super(props);

		const url = location.pathname.split('/').filter((n) => n);

		this.state = {
			id: url[1],
			version: url[1],
			id_tel: url[2],
			loadingPage: true,
			confirmaCpf: false,
			telaInicial: false,
			gerarBoleto: false,
			boletoGerado: false,
			visualizarPDF: false,
			error: false,
		};
		this.cpfService = new CPFService(this.state);
		this.boletoService = new BoletoService(this.state);

		this.carregaPagina();

		this.handleVencimentoSelected = this.handleVencimentoSelected.bind(this);
		this.handleParcelaSelected = this.handleParcelaSelected.bind(this);
	}

	componentDidUpdate() {
		window.scrollTo(0, 0);
	}

	componentDidMount() {
		setTimeout(() => window.location.reload(), 1000 * 60 * 5);
		window.scrollTo(0, 0);

		this.setState({
			NContrato: '000',
			aVista: 'R$ 529,92',
			beneficiario: 'Code7',
			debitoCorr: 'R$ 688,35',
			gerado: false,
			idCarteira: '---',
			isSMS: false,
			isSMSComBoleto: false,
			mensagem: '',
			mensagemacao: 'pague',
			mensagemacaogerado: 'pague',
			parcela: 1,
			parcelas: [
				{
					valorCorrigido: 'R$ 0,00',
					valorAVista: 'R$ 0,00',
					numero: 1,
					valorParcela: 'R$ 0,00',
					text: '1x R$ 0,00',
				},
			],
			permiteReemissao: false,
			primVencOriginal: '---',
			qtdParcelasAtraso: '1',
			telRetorno: '---',
			textParcela: '',
			valor1Parcela: 'R$ 0,00',
			vencimento: moment().format('DD/MM/YYYY'),
			vencimentos: [{ value: '---', text: '---' }],
			errorMessage: '',
			loadingValidandoCPF: false,
			telaInicial: true,
			loadingPage: false,
			confirmaCpf: false,
		});
	}

	handleVencimentoSelected(vencimento) {
		this.setState({ vencimento: vencimento });
	}

	handleParcelaSelected(parcela) {
		this.setState(parcela);
	}

	carregaPagina = () => {
		this.cpfService
			.getCPF()
			.then((data) => {
				data = JSON.parse(data);
				if (data.success) {
					// Adicionado metodo para montagem dos dados fixos com o nome do BD
					data = this.cpfService.montaUser(data);
					this.setState({
						nome: data.result.nome,
						nomecompleto: data.result.nomecompleto,
						cpfCnpj: data.result.cpfCnpj,
						telefone: data.result.telefone,
						tipo: data.result.tipo,
						mensagemacao: data.result.mensagemacao,
						mensagemacaogerado: data.result.mensagemacaogerado,
						loadingPage: false,
						// confirmaCpf: true,
					});
				} else {
					this.setState({
						error: true,
						loadingPage: false,
						errorMessage: data.mensagem,
						errorTitle: 'Não conseguimos gerar seu boleto :(',
					});
				}
				this.confirmarCPF('123');
			})
			.catch((err) => {
				this.setState({
					error: true,
					loadingPage: false,
					errorMessage: 'Erro no servidor',
					errorTitle: 'Não conseguimos gerar seu boleto :(',
				});
			});
	};

	getDadosBoleto() {
		console.log('get dados boleto');
		this.boletoService.getDadosBoleto(this.state.token).then((data) => {
			data = JSON.parse(data);

			if (data.success) {
				// Adicionado metodo para montagem dos dados fixos com o nome do BD
				data = this.boletoService.montaBoleto(data);

				const state = {
					idCarteira: data.result.idCarteira,
					isSMS: data.result.isSMS,
					isSMSComBoleto: false,
					gerado: data.result.gerado,
					debitoCorr: data.result.valorCorrigido,
					aVista: data.result.valorAVista,
					valor1Parcela: data.result.valor1Parcela,
					beneficiario: data.result.beneficiario,
					nome: data.result.nome,
					NContrato: data.result.numContrato,
					cpfCnpj: data.result.cpfCnpj,
					tipo: data.result.tipo,
					parcelas: data.result.parcelas || [],
					parcela: data.result.parcela,
					vencimentos: data.result.vencimentos,
					vencimento: data.result.vencimento || [],
					mensagem: data.result.mensagem,
					mensagemacao: data.result.mensagemacao,
					mensagemacaogerado: data.result.mensagemacaogerado,
					textParcela: data.result.textParcela,
					telaInicial: true,
					confirmaCpf: false,
					loadingValidandoCPF: false,
				};

				if (data.result.gerado) {
					this.boletoService
						.reemissaoBoleto(this.state)
						.then((data) => {
							if (data.success) {
								state.debitoCorr = data.result.valor;
								state.aVista = data.result.valor;
								state.linhaDig = data.result.linhaDig;
								state.linkPDF = data.result.linkPDF;
								state.vencimento = moment(
									data.result.vencimento,
									'YYYY-MM-DD'
								).format('DD/MM/YYYY');
								state.mensagem = data.result.mensagem;
								state.mensagemacao = data.result.mensagemacao;
								state.gerarBoleto = false;
								state.boletoGerado = true;
								state.telaInicial = false;
								this.setState(state);
							} else {
								this.setState({
									error: true,
									loadingValidandoCPF: false,
									errorMessage: data.mensagem,
									errorTitle: 'Não conseguimos gerar seu boleto :(',
								});
							}
						})
						.catch((err) => {
							this.setState({
								error: true,
								loadingValidandoCPF: false,
								errorMessage: err.error.mensagem,
								errorTitle: 'Não conseguimos gerar seu boleto :(',
							});
						});
				} else {
					this.setState(state);
				}
			} else {
				this.setState({
					error: true,
					loadingValidandoCPF: false,
					errorMessage: data.mensagem,
					errorTitle: 'Não conseguimos gerar seu boleto :(',
				});
			}
		});
	}

	confirmarCPF = async (digitoCPF) => {
		ReactGA.event({
			category: 'Click',
			action: 'Confirmar CPF',
		});

		console.log(this.state);

		if (
			(this.state.tipo === 'cpf' && digitoCPF.length === 3) ||
			(this.state.tipo === 'cnpj' && digitoCPF.length === 2)
		) {
			if (!!digitoCPF) {
				await this.setState({
					NContrato: '000',
					aVista: 'R$ 529,92',
					beneficiario: '---',
					debitoCorr: 'R$ 688,35',
					gerado: false,
					idCarteira: '---',
					isSMS: false,
					isSMSComBoleto: false,
					mensagem: '',
					mensagemacao: 'pague',
					mensagemacaogerado: 'pague',
					parcela: 1,
					parcelas: [
						{
							valorCorrigido: 'R$ 0,00',
							valorAVista: 'R$ 0,00',
							numero: 1,
							valorParcela: 'R$ 0,00',
							text: '1x R$ 0,00',
						},
					],
					permiteReemissao: false,
					primVencOriginal: '---',
					qtdParcelasAtraso: '1',
					telRetorno: '---',
					textParcela: '',
					valor1Parcela: 'R$ 0,00',
					vencimento: moment().format('DD/MM/YYYY'),
					vencimentos: [{ value: '---', text: '---' }],
					errorMessage: '',
					loadingValidandoCPF: true,
					telaInicial: true,
					confirmaCpf: false,
				});
			}

			this.cpfService
				.validateCPF(digitoCPF)
				.then((data) => {
					if (data.success) {
						this.setState({ token: data.result.token }, this.getDadosBoleto);
					} else {
						this.setState({
							loadingValidandoCPF: false,
							errorModal: true,
							loadingPage: false,
							confirmaCpf: true,
							telaInicial: false,
							errorMessage: `Erro na validação do seu ${this.state.tipo.toUpperCase()}.`,
						});
					}
				})
				.catch((err) =>
					this.setState({
						loadingValidandoCPF: false,
						errorModal: true,
						loadingPage: false,
						confirmaCpf: true,
						telaInicial: false,
						errorMessage: `Erro na validação do seu ${this.state.tipo.toUpperCase()}.`,
					})
				);
		} else {
			this.setState({
				loadingValidandoCPF: false,
				errorModal: true,
				loadingPage: false,
				confirmaCpf: true,
				telaInicial: false,
				errorMessage: `${this.state.tipo.toUpperCase()} Incorreto`,
			});
		}
	};

	gerarBoleto = async () => {
		if (!!this.state.mensagem) {
			swal({
				type: 'warning',
				title: 'Atenção!',
				text: this.state.mensagem,
				confirmButtonColor: '#FAB800',
			}).then(() => {
				this.clicouEmitir();
			});
		} else {
			this.clicouEmitir();
		}
	};

	clicouEmitir = async () => {
		ReactGA.event({
			category: 'Click',
			action: 'Gerar Boleto',
		});
		await this.setState({ gerarBoleto: true, telaInicial: false });
		this.boletoService.gerarBoleto(this.state, (data) => {
			if (data.success) {
				this.setState({
					linhaDig: data.result.linhaDig,
					linkPDF: data.result.linkPDF,
					gerarBoleto: false,
					boletoGerado: true,
					mensagemacao: data.result.success
						? this.state.mensagemacao
						: this.state.mensagemacaogerado,
				});
			} else {
				this.setState({
					error: true,
					gerarBoleto: false,
					confirmaCpf: false,
					errorMessage: data.message ? data.message : 'Erro ao gerar boleto!',
				});
			}
		});
	};

	mostraBoletoGerado = async () => {
		ReactGA.event({
			category: 'Click',
			action: 'Visualizar Boleto',
		});
		await this.setState({ gerarBoleto: false, boletoGerado: true });
	};

	mostraBoletoGeradoPDF = async () => {
		ReactGA.event({
			category: 'Click',
			action: 'Visualizar Boleto PDF',
		});
		await this.setState({ boletoGerado: false, visualizarPDF: true });
	};

	handleModalClosed = () => {
		this.setState({ errorModal: false, textParcela: null });
	};

	render() {
		if (this.state.error) {
			swal({
				type: 'error',
				title: this.state.errorTitle,
				text: this.state.errorMessage,
				confirmButtonColor: '#FAB800',
			});
		}

		if (this.state.errorModal) {
			swal({
				type: 'error',
				title: this.state.errorTitle,
				text: this.state.errorMessage,
				confirmButtonColor: '#FAB800',
			}).then(this.handleModalClosed);
		}

		if (this.state.textParcela) {
			swal({
				type: 'warning',
				title: 'Parcelas',
				text: this.state.textParcela,
				confirmButtonColor: '#FAB800',
			}).then(this.handleModalClosed);
		}

		return (
			<Fragment>
				{this.state.loadingValidandoCPF ? (
					this.state.tipo === 'cpf' ? (
						<Carregamento msg="Validando CPF" />
					) : (
						<Carregamento msg="Validando CNPJ" />
					)
				) : null}
				{this.state.loadingPage ? (
					<Carregamento msg="Carregando Página" />
				) : null}
				{/* {this.state.confirmaCpf ? <TelaCPF f={this.confirmarCPF} data={this.state}  /> : null} */}
				{this.state.telaInicial ? (
					<TelaInicial
						f={this.gerarBoleto}
						vencimento={this.handleVencimentoSelected}
						parcela={this.handleParcelaSelected}
						state={this.state}
					/>
				) : null}
				{this.state.gerarBoleto ? <Carregamento msg="Gerando Boleto" /> : null}
				{this.state.boletoGerado ? (
					<BoletoGerado state={this.state} f={this.mostraBoletoGeradoPDF} />
				) : null}
				{this.state.visualizarPDF ? (
					<TelaPDF link={this.state.linkPDF} />
				) : null}
				{this.state.error ? (
					<TelaErro
						msg={this.state.errorMessage}
						telefone={this.state.telefone}
					/>
				) : null}
			</Fragment>
		);
	}
}

ReactDOM.render(
	<Router>
		<Route path="/" exact component={App} />
	</Router>,
	document.getElementById('root')
);
