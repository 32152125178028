import React from 'react';

import './card-parcela.css';

const CardParcela = () => {
	const filterCard = () => {
		return (
			<div style={{ whiteSpace: 'nowrap' }}>
				<div className="card-detalhe-parcela">
					<div className="card-detalhe-parcela-numero">
						<span>Nº parcela: </span>
						<span className="ml">5</span>
					</div>
					<div className="wrap-valor-detalhe-parcela">
						<div style={{ whiteSpace: 'nowrap' }}>
							<span>Valor: </span>
							R$ 529,92
						</div>

						<div style={{ whiteSpace: 'nowrap' }}>
							<span>Venc.: </span>
							05/05/2021
						</div>
					</div>
				</div>
				<div className="card-detalhe-parcela">
					<div className="card-detalhe-parcela-numero">
						<span>Nº parcela: </span>
						<span className="ml">6</span>
					</div>
					<div className="wrap-valor-detalhe-parcela">
						<div style={{ whiteSpace: 'nowrap' }}>
							<span>Valor: </span>
							R$ 529,92
						</div>

						<div style={{ whiteSpace: 'nowrap' }}>
							<span>Venc.: </span>
							05/06/2021
						</div>
					</div>
				</div>
				<div className="card-detalhe-parcela">
					<div className="card-detalhe-parcela-numero">
						<span>Nº parcela: </span>
						<span className="ml">7</span>
					</div>
					<div className="wrap-valor-detalhe-parcela">
						<div style={{ whiteSpace: 'nowrap' }}>
							<span>Valor: </span>
							R$ 529,92
						</div>

						<div style={{ whiteSpace: 'nowrap' }}>
							<span>Venc.: </span>
							05/07/2021
						</div>
					</div>
				</div>
			</div>
		);
	};

	return <div>{filterCard()}</div>;
};

export default CardParcela;
