import React, { Component, Fragment } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import swal from 'sweetalert2';
import Barra from '../../imgs/barra-yellow.png';
import Impressora from '../../imgs/impressora.png';
import './index.css';
import ReactGA from 'react-ga';
import Code7 from '../../imgs/code7-logo.png';
import Pesquisa from '../../component/pesquisaSatisfacao';

class telaInicial extends Component {
	constructor(props) {
		super(props);
		this.handleOnCopiarClicked = this.handleOnCopiarClicked.bind(this);
		this.handleSwalClosed = this.handleSwalClosed.bind(this);
		this.handleVisualzarClicked = this.handleVisualzarClicked.bind(this);
		this.state = {
			isLoading: false,
			openModal: false,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ isLoading: true });

			this.handleOpenModal();
		}, 10000);
	}

	handleOpenModal = () => {
		const { openModal } = this.state;
		this.setState({ openModal: !openModal });
	};

	handleOnCopiarClicked() {
		ReactGA.event({
			category: 'Click',
			action: 'Copiar Linha Digitável',
		});
		this.setState({ copied: true });
	}

	handleSwalClosed() {
		this.setState({ copied: false });
	}

	handleVisualzarClicked() {
		ReactGA.event({
			category: 'Click',
			action: 'Visualizar Boleto',
		});
		const win = window.open(this.props.state.linkPDF, '_blank');
		win.focus();
	}

	render() {
		if (this.state.copied) {
			swal('Linha digitável copiada!', '', 'success').then(
				this.handleSwalClosed
			);
		}

		console.log(this.props.state);

		const valorBoleto = this.props.state.valorParcela
			? `R$ ${this.props.state.valorParcela}`
			: this.props.state.aVista;

		return (
			<div className="Container-inicial">
				<h1 className="Cabecalho-white">
					{this.props.state.gerado
						? `Emita agora a segunda via do seu boleto!`
						: `Não espere, pague seu boleto agora!`}
				</h1>
				<div className="wholePaper">
					<div className="PapelTop">
						<img alt="impressora" src={Impressora} className="impressora"></img>

						<img alt="code7-logo" src={Code7} className="code7-logo"></img>
						<div className="LinhaUm">
							<div>
								<div className="Title1">
									VALOR<br></br>DO BOLETO<br></br>
								</div>
								<p className="subTitleSpace">{valorBoleto}</p>
								<div className="Title">
									Nome<div className="Texto">{this.props.state.nome}</div>
								</div>
								<div className="TitleSpace">
									Nº Contrato
									<div className="Texto">{this.props.state.NContrato}</div>
								</div>
							</div>

							<div>
								<div style={{ marginBottom: 100 }}></div>
								<p className="subTitleSpace">{this.props.state.id}</p>
								<div className="ajust">
									<div className="Title-gerado">
										Beneficiário
										<div className="Texto">{this.props.state.beneficiario}</div>
									</div>
									<div className="TitleSpace">
										Vencimento
										<div className="Texto">{this.props.state.vencimento}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.props.state.isSMS ? (
						<div>
							<p
								className="labelSMS"
								style={{ fontSize: '1.3em', textAlign: 'center' }}
							>
								Você recebeu um SMS com a linha digitável para realizar o
								pagamento do boleto.
							</p>
							<p className="labelNaoEspere">Não espere, pague agora!</p>
						</div>
					) : (
						<div className="PapelBottom">
							<div className="Linha">
								<img className="Boleto" alt="boleto" src={Barra}></img>
								<div className="itensBottom">
									<p className="textoBottom">
										01234.56789 012345.123456 56789.345678 5 01234567890145
									</p>

									<p className="textoBottom">{this.props.state.linhaDig}</p>
									<CopyToClipboard
										onCopy={this.handleOnCopiarClicked}
										text={this.props.state.linhaDig}
									>
										<button
											className="Button3"
											style={{ backgroundColor: '#3A3A39' }}
										>
											Copiar linha digitável
										</button>
									</CopyToClipboard>
								</div>
							</div>
						</div>
					)}
					{!this.props.state.isSMS || this.props.state.isSMSComBoleto ? (
						<Fragment>
							<span className="labelButtonBig" style={{ whiteSpace: 'nowrap' }}>
								Clique e visualize o seu boleto agora mesmo
							</span>
							<button onClick={this.props.f} className="Button-boleto-gerado">
								{this.props.state.gerado
									? 'Visualizar segunda via'
									: 'Visualizar Boleto'}
							</button>
						</Fragment>
					) : (
						<div style={{ height: '100px' }}></div>
					)}
				</div>
				{this.state.openModal ? <Pesquisa Close={this.handleOpenModal} /> : ''}
			</div>
		);
	}
}

export default telaInicial;
