import React, { Component } from 'react';
import Barra from '../../imgs/barra-title.svg';
import './index.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Warning from '../../imgs/warning.svg'

class telaCPF extends Component {

    constructor(props) {
        super(props);
        this.renderStringCPFCNPJ = this.renderStringCPFCNPJ.bind(this)
        this.state = {
            tresCPF: '',
            openModal: false,
            maxLength: this.props.data.tipo === "cpf" ? 3 : 2,
            mask: this.props.data.tipo === "cpf" ? `.XXX.${this.props.data.cpfCnpj}-XX` : `.XXX.${this.props.data.cpfCnpj}/XXXX-XX`,
            placehoder: this.props.data.tipo === "cpf" ? '---' : '--'
        }
    }

    filterCPF = async (e) => {
        await this.setState({ tresCPF: e.target.value.replace(/[^0-9]/gm, '') })
    }

    // Montagem da saudacao inicial de acordo com tipo de pessoa
    renderStringCPFCNPJ() {
        if (this.props.data.tipo === "cpf") {
            return <div>Olá, {this.props.data.nome.split(' ')[0]}! <br></br> É você?</div>
        } else {
            return <div>Olá! Você é responsável por: <br></br> {this.props.data.nomecompleto}? </div>
        }
    }

    handleOpenModal = () => {
        const { openModal } = this.state;
        this.setState({ openModal: !openModal });
    };


    render() {
        const { openModal } = this.state;
        return (
            <div className="Container-cpf">

                <div className="row">

                    <div className="col col-2 col-tam-img" >
                        <div className="ajust-img-aling">
                            <div className="border-barra-title">
                                <img className="barra-title" src={Barra} alt="barraTitle"></img>
                            </div>
                        </div>
                    </div>
                    <div className="col col-2 col-tam-txt">
                        <h1 className="Cabecalho" style={{ fontSize: this.props.data.tipo === 'cpf' ? '2.2em' : '1.9em' }}>
                            {this.renderStringCPFCNPJ()}
                        </h1>
                        <p className="textoCPF">Para sua segurança, informe os {this.state.maxLength} primeiros dígitos do seu {this.props.data.tipo.toUpperCase()} para autenticar o seu acesso e exibir seu boleto</p>
                        <form>
                        <div className="input">
                            <input type="text" maxLength={this.state.maxLength} className={this.props.data.tipo} placeholder={this.state.placehoder} value={this.state.tresCPF} onChange={this.filterCPF} ></input>
                            <label className={"label_" + this.props.data.tipo }>{this.state.mask}</label>
                            <button className="Button-Black" onClick={() => this.props.f(this.state.tresCPF)}>Acessar</button>
                        </div>
                        </form>
                        <button className="button-cpf-cliente" onClick={() => {
                            this.handleOpenModal()
                        }}>Não conheço o cliente</button>
                    </div>


                </div>
                <Dialog
                    open={openModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="ajustModal">
                    <div className="center-center">
                   <img src={Warning} style={{width: '15%', padding: '5%'}}  alt="warning" />
                   </div>
                    <DialogTitle className="center-center" id="alert-dialog-title"><div className="fs-35">Atenção</div></DialogTitle>
                    <DialogContent>
                        <DialogContentText className="center-center" id="alert-dialog-description" style={{    whiteSpace: 'nowrap'}}>
                            Confirma que não conhece o cliente?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="center-center">
                    <button className="Button-cpf" onClick={() => {
                            this.handleOpenModal()
                        }}><b>Sim</b></button>
                         <button className="Button-cpf" onClick={() => {
                            this.handleOpenModal()
                        }}><b>Não</b></button>
                    </DialogActions>
                    </div>
                </Dialog>

            </div>
        );
    }
}

export default telaCPF;




