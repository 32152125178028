import BaseService from './base.service';
import rp from 'request-promise';

export default class CPFService extends BaseService {
	constructor(state) {
		super(state);
		this.url = `${this.url}cpf/${this.version}`;
	}

	async getCPF() {
		if (!!this.id) {
			return rp(`https://apismsbradesco.xlab.digital/api/v1/lead/${this.id}`);
		} else {
			return new Promise((acept) => {
				acept(
					JSON.stringify({
						success: true,
						result: {
							nome: 'Maria',
							nomecompleto: 'Nome de Demonstração',
							cpfCnpj: '789',
							telefone: '111111111111',
							tipo: 'cpf',
						},
					})
				);
			});
		}
	}

	validateCPF() {
		return new Promise((acept) => {
			setTimeout(() => {
				acept({
					success: true,
					result: {
						token: 'bradesco_eh_lindo',
					},
				});
			}, 2000);
		});
	}

	montaUser(data) {
		if (!data.result) {
			data.result = {};
		}
		if (!data.result.nome) {
			data.result.nome = 'Maria';
			data.result.nomecompleto = 'Nome de Demonstração';
		} else {
			data.result.nomecompleto = data.result.nome;
		}
		return {
			success: true,
			result: {
				nome: data.result.nome,
				nomecompleto: data.result.nomecompleto,
				cpfCnpj: '789',
				telefone: data.result.telefone,
				tipo: 'cpf',
			},
		};
	}
}
