import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import _ from 'lodash';
import Barra from '../../imgs/barra-yellow.png';
import Impressora from '../../imgs/impressora.png';
import Code7 from '../../imgs/code7-logo.png';
import Card from '../../component/card-parcela/index';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import './index.css';

const styles = (theme) => ({
	button: {
		display: 'block',
		marginTop: theme.spacing.unit * 2,
	},
	formControl: {
		minWidth: 100,
	},
});

class telaInicial extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inchecked: false,
			enabled: false,
			openModal: false,
			vencimento: this.props.state.vencimento,
			parcela: '1',
			valorCorrigido: this.props.state.debitoCorr,
			valorAVista: this.props.state.aVista,
			valorParcela: this.props.state.valor1Parcela,
		};
		this.handleVencimentoChange = this.handleVencimentoChange.bind(this);
		this.handleParcelaChange = this.handleParcelaChange.bind(this);
	}

	handleVencimentoChange(event) {
		this.props.vencimento(event.target.value);
		this.setState({ vencimento: event.target.value });
	}

	handleParcelaChange(event) {
		const parcelaInt = parseInt(event.target.value);
		const parcela = _.chain(this.props.state.parcelas)
			.filter((p) => p.numero === parcelaInt)
			.head()
			.value();
		if (parcela) {
			this.props.parcela({
				parcela: parcelaInt,
				valorParcela: parcela.valorParcela,
			});
			this.setState({
				parcela: event.target.value,
				valorCorrigido: parcela.valorCorrigido,
				valorAVista: parcela.valorAVista,
				valorParcela: parcela.valorParcela,
			});
		} else {
			this.setState({ parcela: event.target.value });
		}
	}

	handleOpenModal = () => {
		const { openModal } = this.state;
		this.setState({ openModal: !openModal });
	};

	check = () => {
		const inchecked = this.state.inchecked;
		this.setState({
			inchecked: !inchecked,
		});

		if (inchecked === false) {
			document.getElementById('gerarBoleto').style.backgroundColor = '#FAB628';
			this.setState({
				enabled: true,
			});
		} else if (inchecked === true) {
			this.setState({
				enabled: false,
			});
			document.getElementById('gerarBoleto').style.backgroundColor = '#737372';
		}
	};

	render() {
		const { enabled, openModal, inchecked } = this.state;

		return (
			<div className="Container-inicial">
				<h1 className="Cabecalho-white">Não espere, pague seu boleto agora!</h1>

				<div className="wholePaper">
					<div className="PapelTop">
						<img alt="impressora" src={Impressora} className="impressora"></img>
						<img alt="code7-logo" src={Code7} className="code7-logo"></img>
						<div className="LinhaUm">
							<div>
								<div className="Title2">
									<br></br>À VISTA<br></br>
								</div>
								<p className="subTitleSpace2">{this.state.valorAVista}</p>
								<div className="Title">Nome</div>
								<div className="Texto">{this.props.state.nome}</div>
								<div className="TitleSpace">Nº Contrato</div>
								<div className="Texto">{this.props.state.NContrato}</div>
								<div className="Title3">Nº de parcelas:</div>
								<select
									value={this.state.parcela}
									onChange={this.handleParcelaChange}
									className="select-parcela"
								>
									{this.props.state.parcelas.map((i) => (
										<option key={i.numero} value={i.numero}>
											{i.text}
										</option>
									))}
								</select>
							</div>

							<div>
								<div className="Title1">
									{'1' === this.state.parcela ? 'DÉBITO' : 'TOTAL DAS'}
									<br></br>
									{'1' === this.state.parcela ? 'CORRIGIDO' : 'PARCELAS'}
								</div>
								<p className="subTitleSpace">{this.state.valorCorrigido}</p>
								<div className="Title">
									Beneficiário
									<div className="Texto">{this.props.state.beneficiario}</div>
								</div>
								<div className="TitleSpace">
									Vencimento<div className="Texto">{this.state.vencimento}</div>
								</div>
								<div className="Title3">Vencimentos</div>
								<select
									value={this.state.vencimento}
									onChange={this.handleVencimentoChange}
									className="select-vencimento"
								>
									{this.props.state.vencimentos.map((i) => (
										<option key={i.text} value={i.value}>
											{i.text}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					{this.props.state.isSMS ? (
						<div className="labelSMS">
							Gere o boleto para receber a linha digitável por SMS:
						</div>
					) : (
						<div className="PapelBottom">
							<div className="Linha">
								<img className="Boleto" alt="boleto" src={Barra}></img>
								<div className="itensBottom">
									<div className="TitleBottom2">
										Gere o boleto para visualizar e copiar a linha digitável
									</div>
									<p className="textoBottom">
										XXXXX.XXXXX XXXXX.XXXXXX XXXXX.XXXXXX X XXXXXXXXXXXXXX
									</p>
									<button className="Button2">Copiar linha digitável</button>
								</div>
							</div>
						</div>
					)}
					{/* <p className="labelButton">Gere o boleto para visualizar e copiar a linha digitável</p> */}
					<div className="checkbox">
						<input
							type="checkbox"
							checked={inchecked}
							id="horns"
							name="horns"
							onClick={this.check}
						/>
						<label htmlFor="horns" style={{ whiteSpace: 'nowrap' }}>
							Li e concordo com o boleto
						</label>
					</div>
					<button
						className="Button-boleto-gerado"
						disabled={!enabled}
						id="gerarBoleto"
						onClick={this.props.f}
					>
						Gerar Boleto
					</button>
					<button
						className="Button-detalhes"
						id="gerarBoleto"
						onClick={() => {
							this.handleOpenModal();
						}}
					>
						Detalhes do acordo
					</button>
				</div>

				<Dialog
					open={openModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<div className="ajustModal">
						<div className="center-center"></div>
						<DialogTitle className="center-center" id="alert-dialog-title">
							<div style={{ whiteSpace: 'nowrap' }}>Detalhes do acordo</div>
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								<Card />
							</DialogContentText>
						</DialogContent>
						<DialogActions className="center-center">
							<button
								className="Button-cpf"
								onClick={() => {
									this.handleOpenModal();
								}}
							>
								<b>OK</b>
							</button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(styles)(telaInicial);
