import rp from 'request-promise';
import BaseService from './base.service';
import moment from 'moment';

export default class BoletoService extends BaseService {
	constructor(state) {
		super(state);
		this.url = `${this.url}/${this.version}`;
	}

	getDadosBoleto(token, callback) {
		if (!!this.id) {
			return rp(`https://apismsbradesco.xlab.digital/api/v1/lead/${this.id}`);
		} else {
			return new Promise((accept) => {
				accept(
					JSON.stringify({
						success: true,
						result: {
							idCarteira: '1',
							isSMS: false,
							isSMSComBoleto: false,
							gerado: false,
							debitoCorr: `R$ 688,35`,
							aVista: `R$ 529,92`,
							valor1Parcela: `R$ 529,92`,
							beneficiario: 'Code7',
							nome: 'Nome de Demonstração',
							numContrato: '123456',
							cpfCnpj: '01234567890',
							tipo: 'cpf',
							valorCorrigido: `R$ 688,35`,
							valorAVista: `R$ 529,92`,
							numero: 1,
							valorParcela: `R$ 529,92`,
							parcelas: [
								{
									valorCorrigido: `R$ 688,35`,
									valorAVista: `R$ 529,92`,
									numero: 1,
									valorParcela: `529,92`,
									text: `${1}x R$ ${`529,92`}`,
								},
								{
									valorCorrigido: `R$ 532,18`,
									valorAVista: `R$ 529,92`,
									numero: 2,
									valorParcela: `266,09`,
									text: `${2}x R$ ${`266,09`}`,
								},
								{
									valorCorrigido: `R$ 533,7`,
									valorAVista: `R$ 529,92`,
									numero: 3,
									valorParcela: `177,90`,
									text: `${3}x R$ ${`177,90`}`,
								},
								{
									valorCorrigido: `R$ 537,66`,
									valorAVista: `R$ 529,92`,
									numero: 4,
									valorParcela: `89,61`,
									text: `${4}x R$ ${`89,61`}`,
								},
							],
							parcela: 123,
							vencimentos: [
								{
									data: moment().format('YYYY-MM-DD'),
									text: moment().format('DD/MM/YYYY'),
								},
								{
									data: moment()
										.add(1, 'days')
										.format('YYYY-MM-DD'),
									text: moment()
										.add(1, 'days')
										.format('DD/MM/YYYY'),
								},
								{
									data: moment()
										.add(2, 'days')
										.format('YYYY-MM-DD'),
									text: moment()
										.add(2, 'days')
										.format('DD/MM/YYYY'),
								},
							],
							vencimento: moment()
								.add(1, 'days')
								.format('DD/MM/YYYY'),
						},
					})
				);
			});
		}
	}

	gerarBoleto(state, callback) {
		setTimeout(
			() =>
				callback({
					success: true,
					result: {
						vencimento: moment().format('DD/MM/YYYY'),
						valor: `R$ 1.234,00`,
						linkPDF:
							'https://storage.googleapis.com/flexrelacionamentosinteligentes/exemplo-boleto-bancario.jpg',
					},
				}),
			1000
		);
	}

	reemissaoBoleto(state) {
		const options = {
			method: 'PUT',
			url: `${this.url}`,
			qs: {
				id: this.id,
				access_token: state.token,
				telefone: this.id_tel,
			},
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				vencimento: state.vencimento,
				parcela: state.parcela,
				isSMS: state.isSMS,
			},
			json: true,
		};

		return rp(options);
	}

	montaBoleto(data) {
		console.log(data);
		if (!data.result) {
			data.result = {};
		}
		if (!data.result.nome) {
			data.result.nomecompleto = 'Nome de Demonstração';
		} else {
			data.result.nomecompleto = data.result.nome;
		}
		return {
			success: true,
			result: {
				idCarteira: '1',
				isSMS: false,
				isSMSComBoleto: false,
				gerado: false,
				debitoCorr: `R$ 688,35`,
				aVista: `R$ 529,92`,
				valor1Parcela: `R$ 529,92`,
				beneficiario: 'Code7',
				nome: data.result.nomecompleto,
				numContrato: '123456',
				cpfCnpj: '01234567890',
				tipo: 'cpf',
				valorCorrigido: `R$ 688,35`,
				valorAVista: `R$ 529,92`,
				numero: 1,
				valorParcela: `R$ 529,92`,
				parcelas: [
					{
						valorCorrigido: `R$ 688,35`,
						valorAVista: `R$ 529,92`,
						numero: 1,
						valorParcela: `529,92`,
						text: `${1}x R$ ${`529,92`}`,
					},
					{
						valorCorrigido: `R$ 532,18`,
						valorAVista: `R$ 529,92`,
						numero: 2,
						valorParcela: `266,09`,
						text: `${2}x R$ ${`266,09`}`,
					},
					{
						valorCorrigido: `R$ 533,7`,
						valorAVista: `R$ 529,92`,
						numero: 3,
						valorParcela: `177,90`,
						text: `${3}x R$ ${`177,90`}`,
					},
					{
						valorCorrigido: `R$ 537,66`,
						valorAVista: `R$ 529,92`,
						numero: 4,
						valorParcela: `89,61`,
						text: `${4}x R$ ${`89,61`}`,
					},
				],
				parcela: 123,
				vencimentos: [
					{
						data: moment().format('YYYY-MM-DD'),
						text: moment().format('DD/MM/YYYY'),
					},
					{
						data: moment()
							.add(1, 'days')
							.format('YYYY-MM-DD'),
						text: moment()
							.add(1, 'days')
							.format('DD/MM/YYYY'),
					},
					{
						data: moment()
							.add(2, 'days')
							.format('YYYY-MM-DD'),
						text: moment()
							.add(2, 'days')
							.format('DD/MM/YYYY'),
					},
				],
				vencimento: moment()
					.add(1, 'days')
					.format('DD/MM/YYYY'),
			},
		};
	}
}
