import React, { Component } from 'react';
import './index.css';


class Carregamento extends Component {

    render() {
        return (
            <div className='lds-container'>
                <div className='lds-ellipsis'>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }
}

export default Carregamento;