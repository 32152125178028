import React, { Component } from 'react';
import Code7 from '../../imgs/code7-logo.png';

class TelaErro extends Component {
	render() {
		return (
			<div className="Loading">
				<img className="Logo" src={Code7} alt="code7"></img>
				<div className="LoadingItems">
					<div className="carregamentoLabel">{this.props.msg}</div>
					<p style={{ color: 'white' }}>Entre em Contato com a Code7</p>
					<p style={{ color: 'white' }}>{this.props.telefone}</p>
				</div>
			</div>
		);
	}
}

export default TelaErro;
